<template>
    <div>
    </div>
</template>

<script>
    export default {
        mounted(){
            window.location.href = "./static/documentation.html"
        }
    }
</script>